<template>
  <div>
    <div
      v-if="isSubjectLine"
      class="text-h4"
    >
      Top Performers by Subject Line
    </div>
    <v-divider class="mb-4 mt-2" />
    <v-row>
      <v-col
        v-if="showViewAsCustomer"
        cols="12"
        sm="4"
        lg="3"
        class="mt-2"
      >
        <ViewAsCustomer />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText(dates)"
              label="Month range"
              prepend-icon="mdi-calendar-range"
              readonly
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dates"
            range
            type="month"
            :min="minMonth.local().format()"
            :max="currentMonth"
            show-current
          />
        </v-menu>
      </v-col>
      <template v-if="isSubjectLine">
        <v-spacer />
      </template>
      <template v-if="!isSubjectLine">
        <v-col
          cols="6"
          lg="3"
        >
          <v-select
            v-model="criteria"
            dense
            hide-details
            :items="criteriaOptions"
            outlined
            label="Scope"
            class="mt-2 input-max-width"
            style="display: inline-block;"
            color="primary"
          />
        </v-col>
      </template>
      <v-col
        cols="6"
        lg="2"
      >
        <v-select
          v-model="count"
          dense
          hide-details
          :items="countOptions"
          outlined
          label="Top/Bottom Count"
          class="mt-2 input-max-width"
          style="display: inline-block;"
          color="primary"
        />
      </v-col>
    </v-row>
    <v-row>
      <template v-for="(metric, i) in metricOption">
        <template v-for="(isTop, j) in isTopOptions">
          <v-col
            :key="`${i}-${j}`"
            cols="12"
            lg="6"
          >
            <TopPerformersChartLogic
              :ref="`${metric.value}-${isTop}`"
              :dates="dates"
              :criteria="isSubjectLine ? 2 : criteria"
              :subject-line="isSubjectLine"
              :item-count="count"
              :is-top="isTop"
              :chart-title="metric.text"
              :metric="metric.value"
              :sl-table-title="metric.text"
              :append="metric.append"
              :precision="metric.precision"
            />
          </v-col>
        </template>
      </template>
    </v-row>
  </div>
</template>

<script>
import {
  inputMonthFormat,
  displayMonthFormat
} from '@/shared/constants'
import claims from '@/shared/models/auth/claims'
import { downloadFileMixin } from '@/shared/mixins/general'
import moment from 'moment'

export default {
  name: 'TopPerformersReport',

  components: {
    TopPerformersChartLogic: () => import('@/views/pages/reports/TopPerformersChartLogic'),
    ViewAsCustomer: () => import('@/views/pages/components/user-state/ViewAsCustomer')
  },

  mixins: [downloadFileMixin],

  data () {
    return {
      criteria: 1,
      criteriaOptions: [
        { text: 'Campaign', value: 1 },
        { text: 'Mailing', value: 2 }
      ],
      count: 10,
      countOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 }
      ],
      metricOption: [
        { text: 'Open Rate', value: 'openRate', append: '%', precision: 2 },
        { text: 'Click Rate', value: 'clickRate', append: '%', precision: 2 },
        { text: 'Click to Open Rate', value: 'clickToOpenRate', append: '%', precision: 2 },
        { text: 'Average Read Time', value: 'averageReadSeconds', append: 's', precision: 1 }
      ],
      isTopOptions: [
        true,
        false
      ],
      dateMenu: false,
      dates: [
        moment().subtract(1, 'month').local().format(inputMonthFormat),
        moment().local().format(inputMonthFormat)
      ],
      currentMonth: moment().local().format()
    }
  },

  computed: {
    showViewAsCustomer () {
      return this.$store.getters['auth/hasClaimKV'](claims.MMS_TEBT)
    },
    minMonth () {
      return moment().subtract(24, 'months')
    },
    isSubjectLine () {
      return this.$route.query.subject === 'subjectLine'
    }
  },

  watch: {
    dates: {
      handler (newValue, oldValue) {
        if (newValue && newValue.length === 2) {
          if (moment(newValue[0]).isAfter(moment(newValue[1]))) {
            this.dates = [
              newValue[1],
              newValue[0]
            ]
          }
        }
      }
    }
  },

  methods: {
    dateRangeText (dates) {
      if (!dates || dates.length !== 2) return ''
      if (moment(dates[0]).isSame(moment(dates[1]), 'month')) return moment(dates[0]).format(displayMonthFormat)
      return dates.map(x => moment(x).format(displayMonthFormat)).join(' - ')
    }
  }
}

</script>
