var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isSubjectLine
        ? _c("div", { staticClass: "text-h4" }, [
            _vm._v("\n    Top Performers by Subject Line\n  ")
          ])
        : _vm._e(),
      _c("v-divider", { staticClass: "mb-4 mt-2" }),
      _c(
        "v-row",
        [
          _vm.showViewAsCustomer
            ? _c(
                "v-col",
                {
                  staticClass: "mt-2",
                  attrs: { cols: "12", sm: "4", lg: "3" }
                },
                [_c("ViewAsCustomer")],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "3" } },
            [
              _c(
                "v-menu",
                {
                  ref: "dateMenu",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    value: _vm.dateRangeText(_vm.dates),
                                    label: "Month range",
                                    "prepend-icon": "mdi-calendar-range",
                                    readonly: "",
                                    "single-line": "",
                                    "hide-details": ""
                                  }
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dateMenu,
                    callback: function($$v) {
                      _vm.dateMenu = $$v
                    },
                    expression: "dateMenu"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      range: "",
                      type: "month",
                      min: _vm.minMonth.local().format(),
                      max: _vm.currentMonth,
                      "show-current": ""
                    },
                    model: {
                      value: _vm.dates,
                      callback: function($$v) {
                        _vm.dates = $$v
                      },
                      expression: "dates"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.isSubjectLine ? [_c("v-spacer")] : _vm._e(),
          !_vm.isSubjectLine
            ? [
                _c(
                  "v-col",
                  { attrs: { cols: "6", lg: "3" } },
                  [
                    _c("v-select", {
                      staticClass: "mt-2 input-max-width",
                      staticStyle: { display: "inline-block" },
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        items: _vm.criteriaOptions,
                        outlined: "",
                        label: "Scope",
                        color: "primary"
                      },
                      model: {
                        value: _vm.criteria,
                        callback: function($$v) {
                          _vm.criteria = $$v
                        },
                        expression: "criteria"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "6", lg: "2" } },
            [
              _c("v-select", {
                staticClass: "mt-2 input-max-width",
                staticStyle: { display: "inline-block" },
                attrs: {
                  dense: "",
                  "hide-details": "",
                  items: _vm.countOptions,
                  outlined: "",
                  label: "Top/Bottom Count",
                  color: "primary"
                },
                model: {
                  value: _vm.count,
                  callback: function($$v) {
                    _vm.count = $$v
                  },
                  expression: "count"
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "v-row",
        [
          _vm._l(_vm.metricOption, function(metric, i) {
            return [
              _vm._l(_vm.isTopOptions, function(isTop, j) {
                return [
                  _c(
                    "v-col",
                    { key: i + "-" + j, attrs: { cols: "12", lg: "6" } },
                    [
                      _c("TopPerformersChartLogic", {
                        ref: metric.value + "-" + isTop,
                        refInFor: true,
                        attrs: {
                          dates: _vm.dates,
                          criteria: _vm.isSubjectLine ? 2 : _vm.criteria,
                          "subject-line": _vm.isSubjectLine,
                          "item-count": _vm.count,
                          "is-top": isTop,
                          "chart-title": metric.text,
                          metric: metric.value,
                          "sl-table-title": metric.text,
                          append: metric.append,
                          precision: metric.precision
                        }
                      })
                    ],
                    1
                  )
                ]
              })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }